<template>
    <!-- 摄影师路由 -->
    <div class="lensman-box">
        <banner-carousel></banner-carousel>
        <!-- <member-excellent
            excellent-title="热门摄影师 / Hot"
            router-name="lensmanDetail">
        </member-excellent> -->
        <member-join
            join-title="吉享空间摄影师"
            join-subtitle="轻松成为从业者，实现财务自由第一步"
            :join-pic="join_pic">
        </member-join>
        <filter-member
            :filer-style="filerStyle"
            :filer-sort="filerSort"
            :remote-methods="getLensmanList">
        </filter-member>
        <!-- 内容 -->
        <div class="lensman-list-box">
            <div class="container">
                <el-row :gutter="20" v-if="list.length && list.length > 0">
                    <el-col :span="6" v-for="(item,index) of list" :key="index">
                        <router-link :to="{name: 'lensmanDetail',query:{id:item.id}}">
                            <el-card 
                                :body-style="{ padding: '0px' }" 
                                shadow="hover">
                                <member-list
                                    :item-data="item">
                                </member-list>
                            </el-card>
                        </router-link>
                    </el-col>
                </el-row>
                <div v-else class="lensman-list-nothing">
                    <img :src="require('@/assets/images/lensman/nothing.png')" alt="">
                </div>
            </div>
        </div>
        <!-- 分页器 -->
        <div class="page-box container">
            <el-pagination
                :page-count="parseInt(page.page_count)"
                :current-page="parseInt(page.current_page)"
                layout="prev, pager, next, jumper"
                @current-change="handlePage"
                background>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    data() {
        return {
            join_pic: [
                "images/lensman/chiefLensman.png",
                "images/lensman/celebrityLensman.png",
                "images/lensman/slashMan.png",
            ],
            filerStyle: [
                {
                    id: "1",
                    name: "户外写真"
                },
                {
                    id: "2",
                    name: "二次元"
                },
                {
                    id: "3",
                    name: "影棚写真-单灯艺术肖像"
                },
                {
                    id: "4",
                    name: "影棚写真-多灯肖像"
                },
                {
                    id: "5",
                    name: "商业活动跟拍"
                },
                {
                    id: "6",
                    name: "产品摄影"
                }
            ],
            filerSort: [
                {
                    id: "1",
                    name: "城市首席摄影师",
                },
                {
                    id: "3",
                    name: "网红摄影师",
                },
                {
                    id: "2",
                    name: "斜杠青年",
                },
            ]
        }
    },
    computed: mapState({
        list: state => state.goodsList.list,
        page: state => state.goodsList.page,
    }),
    components:{
        bannerCarousel: () => import('@/components/bannerCarousel'),
        memberExcellent: () => import('@/components/memberExcellent'),
        memberJoin: () => import('@/components/memberJoin'),
        filterMember: () => import("@/components/filterMember"),
        memberList: () => import('@/components/memberList'),
    },
    methods: {
        ...mapMutations([
            "clearList",
            "clearSublits",
            "clearCondition", // 清除筛选条件数据
            "clearActiveRegion", // 清除选中地区数据
            "clearActiveType", // 清除选中类别
            "clearActiveSort", // 清除选中排序方式
        ]),
        ...mapActions([
            "getLensmanExcellent",
            "getLensmanList",
            "getLensmanCondition",
            "getLensmanBanner",
        ]),
        handlePage(arg){
            let active_style = sessionStorage.getItem("active_style") || "0",
            active_sort = sessionStorage.getItem("active_sort") || "1",
            area = sessionStorage.getItem("sift_area") || "";
            this.getLensmanList({
                area,
                style: active_style,
                filter: active_sort,
                page: arg
            });
        },
    },
    beforeRouteEnter (to, from, next) {
        let active_style = sessionStorage.getItem("active_style") || "0",
        active_sort = sessionStorage.getItem("active_sort") || "1",
        area = sessionStorage.getItem("sift_area") || "";
        next(vm => {
            vm.getLensmanExcellent();
            vm.getLensmanList({
                area,
                style: active_style,
                filter: active_sort
            });
            vm.getLensmanCondition();
            vm.getLensmanBanner();
        });
    },
    beforeRouteLeave (to, from, next) {
        sessionStorage.removeItem("active_style");
        sessionStorage.removeItem("active_sort");
        sessionStorage.removeItem("sift_area");
        this.clearList();
        this.clearSublits();
        this.clearCondition();
        this.clearActiveRegion();
        this.clearActiveType();
        this.clearActiveSort();
        next();
    },
}
</script>

<style scoped>
/* 摄影师盒子 */
.lensman-box{
    padding-bottom: 80px;
    /* background-image: url('../assets/images/bg.jpg'); */
    background-color: #fafafa;
    background-size: cover;
}
/*  */
.lensman-list-box{
    margin-top: 50px;
}
.lensman-list-box .el-col{
    margin-bottom: 20px;
}
.lensman-list-nothing{
    text-align: center;
}
</style>
